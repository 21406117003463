.breadCrumbs {
  display: flex;
  padding: 50px 0;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 992px) {
    margin-left: 30px;
  }

  @media (max-width: 767px) {
    padding: 30px 0 20px;
  }
}


.item:not(:last-child) {
  position: relative;

  &::before {
    content: "/";
    color: #ccc;
    position: absolute;
    right: calc(-20px/2 - 2px);
  }
}

.item:last-child {
  font-weight: 600;
  pointer-events: none;
}

.itemLink:hover {
  text-decoration: underline;
}
.button {
  position: fixed;
  top: 300px;
  left: 0;
  z-index: 100;
  cursor: pointer;

  opacity: 0;
  transition: 0.7s;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s linear 0.3s;
  text-transform: uppercase;

  @media (max-width: 992px) {
    display: none;
  }
}

.shown {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
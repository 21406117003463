.form {
  display: grid;
  gap: 25px;

  @media (max-width: 992px) {
    margin: 0 30px;
  }
}

.submitBtn {
  width: 300px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.middleName{
  margin-bottom: 0;
  margin-top: 5px;
}
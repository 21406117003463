@import '../../../generalStyles';

.wrapper {
  @media (max-width: 992px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.section {
  margin-top: 60px;
}

.sliderWrapper {
  position: relative;
}

.slider {
  padding-bottom: 50px !important;
}

.navigationPrev {
  top: 40%;
  left: -50px;
  @include sliderArrow('prev');

  @media (max-width: 1070px) {
    display: none;
  }
}

.navigationNext {
  top: 40%;
  right: -50px;
  @include sliderArrow('next');

  @media (max-width: 1070px) {
    display: none;
  }
}

.customBullets {
  @include customBullet;
}

.activeCustomBullets {
  @include activeCustomBullet;
}

.formWrapper {
  margin: auto 70px;

  @media (max-width: 767px) {
    margin: auto 0;
  }

  h3 {
    font-family: "HelveticaNeueCyr";
    font-weight: 600;
    font-size: 24px;
  }
}
#map {
  width: 100%;
  height: 60vh;
  margin: 30px 0;
}

.contactItemsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 40px 0 40px 40px;
  gap: 40px;

  @media (max-width: 992px) {
    margin: 40px 0;
    gap: 15px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.contactItem {
  display: block;
  border: 2px solid #f5f2f5;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 15px;
  }

  &:not(.contactPhones):hover {
    border: 2px solid #0081d7;
  }

  h3 {
    font-family: "HelveticaNeueCyr";
    font-weight: 600;
    font-size: 24px;
    margin: 15px 0;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  p {
    font-size: 18px;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  a {
    color: #0081d7;
    font-size: 18px;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
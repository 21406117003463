.form {
  margin-right: 20px;
  margin-top: 70px;

  @media (max-width: 767px) {
    margin-right: 0;
  }
}

.buttonSubmit {
  padding: 15px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.inputValidIndicator {
  position: relative;
  padding: 14px 0 14px 40px;
  border-left: 5px solid #0081d7;

  @media (max-width: 767px) {
    border: none !important;
    padding: 14px 0;
  }

  &:last-child {
    border-color: transparent;
  }

  &::before {
    content: '';
    background: #0081d7;
    position: absolute;
    top: 0;
    left: calc(-50px / 2 - 5px / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.step {
  margin-top: 0;
  font-weight: 600;
  font-size: 24px;
}

.addDocs {
  font-size: 16px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.hasError {
  &.inputValidIndicator {
    &::before {
      content: "X";
      font-size: 37px;
      background: #ff3f3f;
      ;
      color: white;
    }

    border-left: 5px solid #ff3f3f;
  }
}

.hasSuccess {
  &.inputValidIndicator {
    &::before {
      content: "\2713";
      font-size: 37px;
      background: #64c000;
      color: white;
    }

    border-left: 5px solid #64c000;
  }
}

#file {
  display: none;
}

.fileLabel {
  padding: 15px 80px;
  margin-bottom: 30px;
  margin-top: 20px;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  user-select: none;
  border-radius: 4px;
  line-height: 1.43;
  font-size: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  background-color: #0081d7;

  &:hover {
    background-color: #0055ba;
  }

  &+p {
    margin-top: -10px;
    font-family: "HelveticaNeueCyr";
    font-weight: 600;
    font-size: 16px;
  }
}

.middleName{
  margin-top: 5px;
  margin-bottom: 0;
}
.iframeWrapper {
  padding: 0 40px;
  margin-top: 40px;
  margin-bottom: 30px;
  iframe{
    border-radius: 5px;
    height: 50vh;
    box-shadow: 0 6px 13px rgba(0, 0, 0, 0.25), 0 4px 6px rgbs(0, 0, 0, 0.22);
  }
}

.description{
  @media (max-width: 992px) {
    padding: 0 15px;
  }
}
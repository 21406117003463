.input {
  background: transparent;
  font-size: 12px;
  height: 45px;
  margin: 30px auto;
  padding: 6px 12px;
  width: 100%;
}

.form {
  margin-bottom: 35px;
}

.btnSubmit {
  padding: 10px 35px;
  font-size: 14px;
  width: 157px;
  height: 42px;
  display: flex;
  justify-content: center;
  margin-top: 15px;

  @media (max-width: 845px) {
    width: 100%;
  }
}

.error{
  font-size: 12px;
}
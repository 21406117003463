.grid {
  display: grid;
  grid-template-columns: repeat(3, 280px);
  gap: 20px;

  @media (max-width: 940px) {
    grid-template-columns: repeat(2, 280px);
    justify-content: center;
  }

  @media (max-width: 640px) {
    grid-template-columns: 280px;
  }
}

.newsSlider {
  padding-bottom: 50px !important;
}

.customBullets {
  // @include customBullet;
  display: none;

  @media (max-width: 620px) {
    display: inline-block;
  }
}

.activeCustomBullets {
  // @include activeCustomBullet;
  display: none;

  @media (max-width: 620px) {
    display: inline-block;
  }
}

.itemPlaceholder {
  height: 370px;
  border-radius: 4px;
}

.sliderPlaceholder {
  height: 380px;
  margin-bottom: 80px;
}

.checkbox {
  label {
    position: relative;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
    padding-left: 70px;
    padding-top: 4px;
    display: block;
  }

  p {
    display: inline-block;
    margin-bottom: 0;
  }
}

.input {
  display: none;

  &:checked~.switcher::before {
    background-color: #0081d7;
  }

  &:checked~.switcher::after {
    transform: translateX(22px);
  }
}

.switcher {
  &::before {
    content: "";
    background-color: #c4c4c4;
    border: 1px solid transparent;
    border-radius: 20px;
    height: 25px;
    left: 0;
    position: absolute;
    top: calc(50% - 25px/2);
    transition: .1s;
    width: 50px;
  }

  &::after {
    content: "";
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 50%;
    height: 20px;
    left: 4px;
    position: absolute;
    top: calc(50% - 25px/2 + 2px);
    transition: .1s;
    width: 20px;
  }
}
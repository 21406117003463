.questionItem {

  &:nth-child(even) {
    h2 {
      background-color: rgb(235, 233, 236);
    }
  }
}

.itemTitle {
  position: relative;
  user-select: none;
  margin: 0;
  padding: 20px 25px;
  cursor: pointer;
  font-family: "HelveticaNeueCyr";
  font-weight: 600;
  font-size: 24px;
  color: #000;

  @media (max-width: 767px) {
    font-size: 16px;
  }

  &:hover {
    background-color: #f0fbff
  }
}

.caret {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 14px solid #747474;
  position: absolute;
  top: calc(50% - 7px);
  right: 25px;
  z-index: 5;
  transition: 0.2s;

  &.open {
    transform: rotate(180deg);
  }
}

.articles {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.2s, padding 0s 0.2s;

  &.open {
    grid-template-rows: 1fr;
    padding: 30px;
    transition-delay: 0s;

    @media (max-width: 767px) {
      padding: 10px;
    }
  }
}

.articleTitle {
  font-size: 16px;
  font-family: "HelveticaNeueCyr";
  font-weight: 600;
}

.articleText {
  margin: 20px;
}

.toAllArticles {
  text-decoration: underline;
  color: #0081d7;

  &:hover {
    color: #000;
    text-decoration: none;
  }
}
.pagination {
  display: flex;
  margin: 50px 0 10px;

  @media (max-width:992px) {
    margin-left: 30px;
  }
  @media (max-width: 510px) {
    justify-content: center;
    margin: 50px 10px 0;
  }
}

.item {
  height: 35px;
  width: 35px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #337ab7;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    user-select: none;
    cursor: pointer;
  }

  &.active span {
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
  }

  &.disabled span {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
  }
}
.imgWrapper{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.description{
  @media (max-width: 992px) {
    padding: 0 15px;
  }
}
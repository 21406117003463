.footerSection {
  position: relative;
  color: #747474;

  @media (max-width: 767px) {
    padding: 0 30px 10px;

    &::after {
      content: "";
      background-color: #bbbbbb;
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.title {
  color: #000;
  margin: 20px 0;
  font-size: 16px;
  font-weight: 600;

  @media (max-width: 767px) {
    width: 100%;
    position: relative;
  }
}

.caret {
  position: absolute;
  right: 0;
  display: none;
  margin-top: -10px;

  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;

  @media (max-width: 767px) {
    display: block;
  }
}

.innerContent {
  @media (max-width: 767px) {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: grid-template-rows 0.2s;

    &.open {
      grid-template-rows: 1fr;
    }
  }
}
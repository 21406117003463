@import '../../../generalStyles';

.slider_1 {
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 620px) {
    padding-bottom: 35px !important;
  }

  @media (max-width: 986px) {
    iframe {
      height: 230px;
    }
  }
}

.slider_2 {
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 35px !important;

  @media (max-width: 986px) {
    iframe {
      height: 230px;
    }
  }
}

.navigationPrev {
  @include sliderArrow('prev');
  top: 40%;
  left: -50px;

  @media (max-width: 1070px) {
    display: none;
  }
}

.navigationNext {
  @include sliderArrow('next');
  top: 40%;
  right: -50px;

  @media (max-width: 1070px) {
    display: none;
  }
}

.iframeText {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 7px;

  a {
    font-size: 18px;
    color: #000;
    font-family: "HelveticaNeueCyr";
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
}

.slide {
  padding: 0 15px
}

.customBullets_1 {
  @include customBullet;
  display: none;

  @media (max-width: 620px) {
    display: inline-block;
  }
}

.activeCustomBullets_1 {
  @include activeCustomBullet;
  display: none;

  @media (max-width: 620px) {
    display: inline-block;
  }
}

.customBullets_2 {
  @include customBullet;
}

.activeCustomBullets_2 {
  @include activeCustomBullet;
}

.slidesBlock {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@import '../../../generalStyles';

.footer {
  background-color: #ebe9ec;
}

.items {
  margin: 0 auto 20px;
  width: 841px;
  display: flex;
  font-size: 14px;
  font-family: "HelveticaNeueCyr";
  font-weight: 600;
  padding: 60px 0;

  @media (max-width: 992px) {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 767px) {
    text-align: left;
  }
}

.column {
  margin: 0 auto;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.social {
  width: 25px;
  height: 25px;

  img {
    filter: grayscale(100%);

    &:hover {
      filter: grayscale(0);
    }
  }

  @media (max-width: 767px) {
    margin: 0 auto;
    display: block;
  }
}

.smallText {
  font-size: 12px;
}

.link {
  color: #599edc;
  font-size: 12px;

  &:hover{
    text-decoration: underline;
  }
}

.navlink {
  display: block;
  margin-bottom: 10px;

  &:hover{
    color: #000;
  }
}

.hr {
  display: block;
  width: 60%;
  height: 2px;
  background-color: #bbb;
  margin: 0 auto;
}

.bottom {
  display: flex;
  justify-content: center;
  margin-top: 75px;
  padding-bottom: 75px;
  align-items: center;

  @media (max-width: 767px) {
    margin: 20px 15px 0 15px;
  }
}

.bottomText {
  margin: 0 40px;

  @media (max-width: 767px) {
    margin: 0;
  }

  p {
    text-align: center;
    margin: 0;
    color: #737373;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}
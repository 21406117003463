@import '../../../generalStyles';

.wrapper {
  @media (max-width: 992px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    margin-left: -40px;

    @media (max-width: 992px) {
      margin-left: 0;
    }
  }
}

.mission {
  display: flex;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  .content {
    margin: 20px;

    @media (max-width: 992px) {
      margin: 20px 0;
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    p {
      font-family: "HelveticaNeueCyr";
      font-weight: 600;
      font-size: 24px;
      margin: 0;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
  }

  .statistic {
    margin: auto 30px;
    max-width: 200px;

    @media (max-width: 992px) {
      text-align: center;
      max-width: none;
      margin-right: 0;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin: 0;

      p {
        width: 200px;
        margin: 0 auto !important;
      }
    }

    p {
      font-size: 16px;

      &:first-child {
        color: #0081d7;
        text-align: center;
        font-size: 50px;
        font-weight: 600;
        margin: 0;
      }
    }
  }
}

.advantage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.advantageItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  img {
    width: 140px;
    height: 140px;
  }

  p {
    font-family: "HelveticaNeueCyr";
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    margin-top: 25px;

    @media (max-width: 767px) {
      font-size: 12px;
      margin-top: 0;
      padding: 0 5px;
    }
  }
}

.job {
  max-width: 50%;

  @media (max-width: 767px) {
    max-width: 100%;
  }

  h3 {
    font-family: "HelveticaNeueCyr";
    font-weight: 600;
    font-size: 21px;
  }

  p {
    margin-bottom: 30px;
  }
}


.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
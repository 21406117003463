.wrapper {
  padding: 50px;
  text-align: center;
  min-height: 50vh;

  h1{
    margin: 0 0 40px;
    font-size: 36px;
    font-weight: 700;
    color: #0081d7;
  }

  p{
    margin: 0 0 20px;
    font-size: 18px;
    line-height: 26px;
  }

  a{
    color: #0081d7;
  }
}
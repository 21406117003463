@import '../../../generalStyles';

.wrapper {
  @media (max-width: 992px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  h2 {
    margin-left: -40px;

    @media (max-width: 992px) {
      margin-left: 0;
    }
  }

  h3 {
    font-family: "HelveticaNeueCyr";
    font-weight: 600;
    font-size: 24px;
  }
}

.categoriesList {
  display: grid;
  margin-bottom: 50px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

  @media (max-width: 767px) {
    grid-template-columns: inherit;
  }
}

.vacanciesList {
  margin-bottom: 50px;

  li {
    margin-left: 20px;
    margin-bottom: 10px;
  }

  a {
    text-decoration: underline;
    color: #000;
    font-size: 16px;

    &:hover {
      color: #23527c;
    }
  }
}

.bottomBlock {
  padding-top: 10px
}

.linkButton {
  margin: 15px 0;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.titlePlaceholder {
  height: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 250px;
}

.textPlaceholder {
  margin-left: 20px;
  margin-bottom: 10px;
  height: 17px;
  width: 140px;
}
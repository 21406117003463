.questions {
  margin: 0 50px;

  @media (max-width: 992px) {
    margin: 0 10px;
  }
}

.placeholderWrapper {
  display: grid;
  gap: 2px;
  margin: 0 50px;

  @media (max-width: 992px) {
    margin: 0 10px;
  }
}

.itemPlaceholder {
  height: 64px;
}
.wrapper {
  background-color: #f0fbff;
  margin-bottom: -100px;
  padding-bottom: 100px;
}

.title {
  margin-top: 0;
  padding-top: 20px;
}

.yearsWrapper {
  display: flex;
  margin: 50px 0;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    justify-content: center;
  }
}

.year {
  color: #000;
  user-select: none;
  background-color: #fff;
  font-family: "HelveticaNeueCyr";
  font-weight: 600;
  font-size: 16px;
  margin: 10px;
  padding: 15px 35px;
  border-radius: 4px;

  &:hover {
    background-color: #f5f2f5;
  }

  @media (max-width: 900px) {
    margin: 5px;
  }

  &.currenYear {
    background-color: #0081d7;
    color: #fff;
  }
}

.news {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  padding: 0 10px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    max-width: 430px;
    margin: 0 auto;
  }
}

.firstItemWrapper {
  grid-column: 1/3;
  height: 350px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  position: relative;
  overflow-y: hidden;

  @media (max-width: 900px) {
    grid-column: 1/2;
    min-height: 470px;
    height: auto;

    &>a {
      height: 260px;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    background-image: linear-gradient(to top, #02254e 0%, transparent 100%);
  }

  &:hover {
    box-shadow: 0 14px 28px #2a5e8a40, 0 10px 10px #295d8052;
  }

  img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    object-fit: cover;
    object-position: center;
    min-height: 100%;
  }
}

.newsPageLink {
  display: block;
  height: 260px;
}

.firstItemDescription {
  margin: 0 70px;
  height: 200px;
  width: calc(100% - 140px);
  position: absolute;
  bottom: 0;
  color: #fff;
  font-size: 24px;
  font-family: "HelveticaNeueCyr";
  font-weight: 600;
  z-index: 2;

  @media (max-width: 900px) {
    position: relative;
    font-size: 16px;
    margin: 0;
    width: 100%;
    padding: 20px 15px;
  }

  a {
    display: block;
  }

  p {
    color: #fff;
  }

  &>div {
    bottom: 5px;
    right: 40px;

    @media (max-width: 900px) {
      bottom: 37px;
      right: 6px;
    }
  }

  button {
    filter: brightness(0) invert(1);
  }
}

.noNews {
  text-align: center;
}

.itemPlaceholder {
  height: 470px;

  &:first-child {
    height: 350px;
    grid-column: 1/3;


    @media (max-width: 900px) {
      height: 470px;
    }
  }
}
.wrapper {
  width: 100%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  position: relative;

  &:hover {
    box-shadow: 0 14px 28px #2a5e8a40, 0 10px 10px #295d8052;
  }

  img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    object-fit: cover;
    object-position: center;
    height: 100%
  }
}

.imageLink {
  display: block;
  height: 170px;
}

.description {
  position: relative;
  padding: 20px 15px;
  height: 210px;

  a {
    color: #000;
    font-family: "HelveticaNeueCyr";
    font-weight: 600;
    font-size: 16px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.date {
  position: absolute;
  bottom: 11px;
  left: 11px;
  font-size: 12px;
  color: #aaa;
  margin: 0;
  font-family: "HelveticaNeueCyr";
  font-weight: 100;
}

.share {
  position: absolute;
  bottom: 11px;
  right: 11px;
  height: 20px;
  width: 20px;
}

.social {
  position: absolute;
  bottom: 37px;
  right: 6px;
  z-index: 5;
  padding: 6px;
  border-radius: 8px;
  background-color: #fff;
}
.description {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 1070px;
  margin: 25px auto 0;

  @media (max-width: 1170px) {
    flex-direction: column;
    margin: auto;
    width: auto;
  }
}

.text {
  font-size: 16px;

  @media (max-width: 1170px) {
    width: 100%;
    padding: 0 15px;
  }
}

.title {
  font-family: "HelveticaNeueCyr";
  font-weight: 100;
  font-size: 30px;
  margin-top: 40px;
}

.video {
  width: 75%;
  margin: 30px 0;
  height: 280px;

  @media (max-width: 1170px) {
    width: 100%;
    height: 50vh;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: auto;
  }
}

.iframe {
  border-radius: 5px;
}

.count {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;

  >* {
    margin: 0;
  }
}

.number {
  font-size: 35px;
  color: #0081d7;
  font-weight: 600;
}

.faqBtn {
  display: block;
  margin-top: 20px;
}
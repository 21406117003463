@import '../../../generalStyles';

.button {
  position: fixed;
  top: 500px;
  z-index: 100;
  right: 17px;
  cursor: pointer;
  text-transform: uppercase;

  @media (max-width: 1280px) {
    right: 0;
  }

  @media (max-width: 992px) {
    display: none;
  }
}
.button {
  display: block;
  color: #fff;
  text-decoration: none;
  user-select: none;
  padding: 15px 35px;
  border-radius: 4px;
  line-height: 1.43;
  font-family: "HelveticaNeueCyr";
  font-size: 16px;
  outline: none;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.2s;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.primary {
  background-color: #0081d7;

  &:hover {
    background-color: #0055ba;
  }
}

.red {
  background-color: #ff3f3f;

  &:hover {
    background-color: #ff0000;
  }
}

.small {
  padding: 10px 35px;
  font-size: 14px;
}
@import '../../../generalStyles';

.wrapper {
  @media (max-width: 992px) {
    padding: 0 15px;

    strong {
      margin-left: 30px;
    }
  }

  strong {
    display: block;
    margin-top: 15px !important;
  }
}

.link {
  display: block;
  margin-top: 35px;
  text-align: center;
  width: 100%;
}

.linkButton {
  @media (max-width: 767px) {
    width: 100%;
  }
}
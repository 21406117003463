.wrapper {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  background-color: #0056b6;
  bottom: 0;
  transition: 0.2s ease-in;
  max-height: 0;
  overflow: auto;
  z-index: 100;

  &>ul {
    text-align: center;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &>li {
      padding: 20px 0;

      li {
        margin-left: -12px;
        a {
          font-size: 14px;
          &::before {
            content: "-";
            margin-right: 8px;
          }
        }
      }
    }
  }

  &.active {
    overflow: auto;
    max-height: calc(100vh - 70px);
  }
}

.link {
  display: block;
  color: white;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}
.mainText {
  margin: 50px;
  border-bottom: 3px solid #747474;

  @media (max-width: 767px) {
    margin: 30px;
  }

  h2 {
    font-size: 20px;
    font-family: "HelveticaNeueCyr";
    font-weight: 600;
  }

  &>p {
    margin: 20px 40px 40px;

    @media (max-width: 767px) {
      margin: 20px 10px 30px;
    }
  }

  p,
  span {
    font-size: 18px !important;
  }
}
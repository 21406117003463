.thanksWrapper {
  width: 750px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 767px) {
    width: 500px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 510px) {
    width: 250px;
    grid-template-columns: 1fr;
  }

  img {
    cursor: zoom-in;
  }
}

.thank {
  margin: 20px;
  height: 300px;
}

.thankImage {
  object-fit: cover;
}

.thanksPlaceholder {
  height: 300px;
  margin: 20px;
}
.thanksContainer {
  display: flex;
  margin: 50px 0;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.thanksItem {
  display: block;
  font-size: 80px;
  font-family: "HelveticaNeueCyr";
  font-weight: 600;
  line-height: 1;
  border: 2px solid #0081d7;
  transition: 0.6s;
  padding: 15px 20px;
  color: #0081d7;

  &:hover {
    border: 2px solid #0055ba;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: #fff;
    background-color: #0055ba;
  }

  &:focus {
    text-decoration: underline;
    outline: none;
  }
}

.itemPlaceholder{
  height: 114px;
  width: 220px;
}
.buttonSubmit {
  width: 300px;
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 992px) {
    width: 100%;
  }
}

.inputGroup {
  width: 40%;
  display: grid;
  gap: 20px;

  @media (max-width: 992px) {
    width: 100%;
  }
}

.feedbackText {
  position: relative;
  margin-top: 20px;
}

.recomendBtn {
  font-family: inherit;
  right: 0;
  color: #0081d7;
  position: absolute;
  top: -20px;

  &:hover {
    text-decoration: underline;
  }
}

.recomendList {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 20px;
  display: none;
  z-index: 5;

  h3 {
    margin: 10px 0;
    font-size: 12px;
  }

  li {
    font-size: 12px;
    margin: 5px auto 0px 15px;
  }

  &.open {
    display: block;
  }
}
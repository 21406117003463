@import '../../../generalStyles';

.wrapper {
  margin-left: 80px;

  @media (max-width: 767px) {
    margin: 0 25px 0 15px;
  }
}

.faq {
  max-width: 50%;

  @media (max-width: 767px) {
    max-width: 100%;
  }

  h2 {
    font-family: "HelveticaNeueCyr";
    font-weight: 600;
    font-size: 24px;
  }

  p {
    font-size: 16px
  }

  a {
    margin: 15px 0;
  }
}

.blueButton {
  display: block;
}

.redButton {
  background-color: #ff3f3f;
}
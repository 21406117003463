.wrapper {

  &[data-status="success"] {
    .label {
      color: #3c763d;
    }

    .input {
      border-color: #3c763d;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);

      &:focus {
        border-color: #2b542c;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 6px #67b168;
      }
    }
  }

  &[data-status="error"] {
    .label {
      color: #a94442;
    }

    .input {
      border-color: #a94442;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);

      &:focus {
        border-color: #843534;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 6px #ce8483;
      }
    }
  }
}

.label {
  display: block;
  font-family: "HelveticaNeueCyr";
  font-weight: 600;
  margin-bottom: 5px;
}

.input {
  font-family: inherit;
  display: block;
  width: 100%;
  height: 34px;
  padding: 30px 15px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
  }
}
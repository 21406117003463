.wrapper {

  @media (max-width: 768px) {
    padding: 15px;
  }
}

.titlePlaceholder {
  height: 30px;
  width: 50%;
  margin-top: 20px;
  margin-bottom: 10px;

  @media (max-width: 992px) {
    margin-left: 30px;
    width: 75%;
  }
}

.textBlock {
  margin-top: 35px;
}

.textPlaceholder {
  height: 20px;
  margin-top: 15px;
  width: 90%;

  @media (max-width: 992px) {
    width: 100%;
  }

  &:nth-child(2) {
    width: 80%;

    @media (max-width: 992px) {
      width: 95%;
    }
  }

  &:nth-child(3) {
    @media (max-width: 992px) {
      width: 90%;
    }
  }

  &:nth-child(4) {
    width: 80%;

    @media (max-width: 992px) {
      width: 95%;
    }
  }

  &:nth-child(5) {
    width: 70%;

    @media (max-width: 992px) {
      width: 85%;
    }
  }
}
.container{
  display: grid;
  grid-template-columns: 420px 420px;
  gap: 25px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.item{
  height: 305px;

  @media (max-width: 767px) {
    &:nth-child(2){
      display: none;
    }
  }
}
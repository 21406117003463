.header {
  width: 100%;
  background-color: #002a6d;
  height: 90px;
  position: relative;

  @media (max-width: 767px) {
    height: 70px;
  }
}

.container {
  height: 100%;
  margin: auto;
  width: max-content;
  padding: 0 15px;
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 35px 0 25px;
    justify-content: space-between;
  }
}

.logo {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 5px;

  .logo2 {
    @media (max-width:886px) {
      display: none;
    }
  }
}

.nav {
  display: flex;
  height: 100%;

  @media (max-width: 767px) {
    display: none;
  }

  li {
    position: relative;
    transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);

    .innerList {
      position: absolute;
      visibility: hidden;
      display: flex;
      align-items: center;
      height: 100%;
      top: 100%;
      left: 0;
      background-color: #0055ba;
      opacity: 0;
      z-index: 20;
      transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1), visibility 0s linear 0.3s;
    }

    .innerList1 {
      width: 517px;
    }

    .innerList2 {
      width: 369px;
    }

    .innerList3 {
      width: 195px;
    }

    &:hover {
      .innerList {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
      }

      .link {
        background-color: #0055ba;
      }
    }
  }
}

.link, .activeLink {
  display: block;
  padding: 15px;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  height: 100%;
  transition: inherit;
}

.activeLink {
  background-color: #0055ba;
}

.burger {
  display: none;
  position: relative;
  width: 25px;
  height: 16px;
  background-color: transparent;

  @media (max-width: 767px) {
    display: block;
  }

  &.active {
    .burgerLine {
      &:first-child {
        transform: rotate(-45deg) translate(-8px, 3px);
      }

      &:last-child {
        transform: rotate(45deg) translate(-6px, -1px);
      }
    }
  }
}

.burgerLine {
  background-color: #fff;
  width: 100%;
  height: 3px;
  position: absolute;
  transition: 0.2s;

  &:first-child {
    top: 0;
    left: 0;
  }

  &:last-child {
    bottom: 0;
    left: 0;
  }
}
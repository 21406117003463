.wrapper {
  position: relative;
  padding: 15px 10px 30px;
  background-color: #f5f2f5;
  margin: 10px 10px;
  border-radius: 3px;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.25), 0 11px 18px rgba(0, 0, 0, 0.12);
}


.title {
  font-size: 16px;
  font-family: "HelveticaNeueCyr";
  font-weight: 600;
  font-size: 16px;
  color: #000;

  p {
    margin: 15px 0;
  }
}

.itemDate {
  position: absolute;
  bottom: 0;
  font-family: "HelveticaNeueCyr";
  font-weight: 100;
  font-size: 12px;
}

.itemDescription {
  overflow: hidden;
  font-size: 16px;
  transition: 0.3s;

  >*:first-child {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  *{
    display: none;
  }
}

.fullText {
  display: block;
  text-decoration: underline;
  color: #000;
  margin-top: 15px;
}
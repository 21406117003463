.sliderWrapper {
  position: relative;
  width: 950px;
  margin: 0 auto;
  user-select: none;

  @media (max-width: 992px) {
    width: 100%;
    padding: 0 20px;
  }
}

.title {
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left: -25px;
  font-family: "HelveticaNeueCyr";
  font-weight: 100;
  font-size: 30px;

  @media (max-width: 992px) {
    margin-left: auto;
  }
}

.customBullets {
  display: none;

  @media (max-width: 620px) {
    display: inline-block;
  }
}

.activeCustomBullets {
  display: none;

  @media (max-width: 620px) {
    display: inline-block;
  }
}

.linkWrapper {
  display: flex;
  justify-content: center;
  margin: 30px;
}
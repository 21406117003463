@font-face {
  font-family: "HelveticaNeueCyr";
  src: url(../src/assets/fonts/HelveticaNeueCyr-Light.ttf);
  font-weight: 100;
}

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url(../src/assets/fonts/HelveticaNeueCyr-Roman.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url(../src/assets/fonts/HelveticaNeueCyr-Medium.ttf);
  font-weight: 600;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  font-family: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.1;
}

h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h2 {
  font-family: "HelveticaNeueCyr";
  font-weight: 100;
  font-size: 30px;
}

select {
  cursor: pointer;
}

body {
  margin: 0;
  color: #333;
  font-family: "HelveticaNeueCyr";
  line-height: 1.42857143;
  font-size: 14px;
}

#root {
  height: 100vh;
  overflow: auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

p {
  margin-bottom: 10px;
}

.grecaptcha-badge {
  display: none !important;
}

.ya-share2__title {
  display: none !important;
}
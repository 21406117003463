.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  visibility: hidden;
  transition: opacity 0.1s, visibility 0s 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
    transition: 0.2s;

    .imgWrapper {
      opacity: 1;
      transition: 0.15s;
      transition-delay: 0.1s;
      transform: scale(1);
    }
  }
}

.imgWrapper {
  height: 95%;
  padding: 15px;
  margin: 15px;
  background-color: white;
  border-radius: 4px;
  position: relative;
  transform: scale(0.8);
  transition: 0.3s;
  transition-delay: 0s;
  opacity: 0;
  display: flex;

  img {
    height: 100%;
  }

  @media (max-width: 767px) {
    height: auto;
    width: 90%;
    padding: 7px;

    img {
      width: 100%;
    }
  }
}

.close {
  position: absolute;
  background-size: 44px 152px;
  background-image: url(../../../assets/images/icons/gallerySprite.png);
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.slideButton {
  position: absolute;
  width: 35%;
  height: calc(100% - 30px);
  top: 15px;
}

.buttonIcon {
  position: absolute;
  top: calc(50% - 34px/2);
  width: 36px;
  height: 34px;
  background-size: 44px 152px;
  display: none;
}

.prev {
  left: 15px;

  &:hover .prevIcon {
    display: block;
  }
}

.prevIcon {
  background-image: url(../../../assets/images/icons/gallerySprite.png);
  background-position: 0 -36px;
  left: 10px;
}

.next {
  right: 15px;

  &:hover .nextIcon {
    display: block;
  }
}


.nextIcon {
  background-image: url(../../../assets/images/icons/gallerySprite.png);
  background-position: 0 -72px;
  right: 10px;
}
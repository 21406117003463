.placeholderBlock {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 0 15px;

  @media (max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 620px) {
    grid-template-columns: 1fr;
  }
}

.itemPlaceholder {
  @media (max-width: 920px) {

    &:nth-child(3),
    &:nth-child(6) {
      display: none;
    }
  }

  @media (max-width: 620px) {
    padding-bottom: 35px;

    &:nth-child(2),
    &:nth-child(5) {
      display: none;
    }
  }
}

.imagePlaceholder {
  height: 150px;

  @media (max-width: 986px) {
    height: 230px;
  }
}

.linkPlaceholder {
  width: 75%;
  margin: 22px auto 0;
  height: 25px;
}
@import '../../../generalStyles';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  transition: opacity 0.2s, visibility 0s 0.2s;
  opacity: 0;
  pointer-events: none;

  @media (max-width: 992px) {
    padding: 15px;
  }
}

.active {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
  transition-delay: 0s;

  .content {
    transform: translateY(0);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  height: 50%;
  width: 950px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 19px 28px rgba(0, 0, 0, 0.25), 0 0px 31px rgba(0, 0, 0, 0.22);
  transform: translateY(-30%);
  transition: 0.2s;

  @media (max-width: 992px) {
    height: auto;
    padding: 25px 15px;
  }
}

.title {
  font-size: 28px;
  font-family: "HelveticaNeueCyr";
  font-weight: 100;
  margin: 10px 0;
}

.description {
  font-family: "HelveticaNeueCyr";
  font-weight: 600;
  font-size: 24px;
  margin: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.iconWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.icon {
  color: #1ab20e;
  font-size: 45px;
  border-radius: 50%;
  border: 5px solid #1ab20e;
  padding: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 0.8;

  &:before {
    content: '\2713';
  }
}
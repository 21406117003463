.wrapper {
  &[data-status="success"] {
    .select {
      border-color: #3c763d;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);

      &:focus {
        border-color: #2b542c;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 6px #67b168;
      }
    }
  }

  &[data-status="error"] {
    .select {
      border-color: #a94442;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);

      &:focus {
        border-color: #843534;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 6px #ce8483;
      }
    }
  }
}

.select {
  width: 100%;
  font-family: inherit;
  height: auto;
  padding: 21px 15px;
  font-size: 14px;
  line-height: 1.43;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
  }
}
.image {
  margin-top: 15px;
  height: 350px;
  border-radius: 5px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  margin-bottom: 50px;

  @media (max-width: 992px) {
    margin-left: 10px;
    margin-right: 10px;
    height: auto;
  }

  img {
    height: 100%;
    width: 100%;
    max-height: 350px;
    object-fit: cover;
    object-position: top;
    border-radius: 5px;
    vertical-align: middle;
  }
}

.social {
  padding: 7px;
  border-radius: 5px;
  box-shadow: 1px 1px 9px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: -50px;
  background-color: #fff;

  @media (max-width: 992px) {
    top: 5px;
    left: 15px;
  }
}

.wrapper {
  position: relative;

  h1 {
    max-width: 70%;
  }
}

.content {
  margin: auto 100px;

  @media (max-width: 767px) {
    margin: auto 40px;
  }
}

.imagePlaceholder {
  margin-top: 15px;
  height: 350px;

  @media (max-width: 992px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (max-width: 767px) {
    height: 170px;
  }
}
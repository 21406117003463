@import '../../../generalStyles';

.sliderWrapper {
  position: relative;
}

.slider {
  padding-bottom: 35px !important
}

.navigationPrev {
  top: 40%;
  left: -50px;
  @include sliderArrow('prev');

  @media (max-width: 1070px) {
    display: none;
  }
}

.navigationNext {
  top: 40%;
  right: -50px;
  @include sliderArrow('next');

  @media (max-width: 1070px) {
    display: none;
  }
}

.customBullets {
  @include customBullet;
}

.activeCustomBullets {
  @include activeCustomBullet;
}
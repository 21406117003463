//стили для пагинации Swiper
@mixin customBullet {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: silver;
  vertical-align: middle;
}

@mixin activeCustomBullet {
  transform: scale(1.5);
  border-radius: 50%;
  background-color: #0081d7;
}

//стрелки для Swiper
@mixin sliderArrow($direction) {
  position: absolute;
  cursor: pointer;
  z-index: 5;
  height: 54px;
  width: 20px;

  @if $direction =='prev' {
    background-image: url(./assets/images/prev.png);
  }

  @if $direction =='next' {
    background-image: url(./assets/images/next.png);
  }
}

//swiper
.swiper-pagination-bullets.swiper-pagination-horizontal {
  font-size: 0;
  bottom: 7px !important;
}
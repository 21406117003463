.newsBackground {
  background-color: #f0fbff;
  padding: 20px;
}

.news {
  margin: auto;
  width: 900px;

  @media (max-width: 992px) {
    width: auto;
  }
}

.title {
  margin-left: -50px;
  font-family: "HelveticaNeueCyr";
  font-weight: 100;
  font-size: 30px;

  @media (max-width: 992px) {
    margin-left: auto;
  }
}

.linkWrapper {
  margin: 30px;
  display: flex;
  justify-content: center;
}